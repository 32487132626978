import React, { ReactNode, useEffect, useState } from "react";
import {
  useFlowContext,
  useFlowDispatchContext,
} from "../../context/FlowContext";
import { useUserContext } from "../../context/UserContext";
import {
  ASSET_PERSONAL_ESTATE_TYPES,
  ASSET_SECURITIES_TYPES,
  FAMILY_RELATION_OPTIONS,
  flowData,
  flowSections,
  flowSets,
  RELATION_OPTIONS,
} from "../../services/flow";
import { getExpandCircleIcon, getPencilIcon } from "../../services/icons";
import {
  dataDeepCopy,
  getPersonFullLabel,
  processQuestionLabel,
} from "../../services/utils";
import { FlowActionSetEdit, FlowActionType, FlowItem } from "../../types/flow";
import RightColumnHeader from "./RightColumnHeader";

type SummaryType = { [key: string]: { label: string; val: string[] }[] };

export default function Summary({ fullPage = false }) {
  const [activeSection, setActiveSection] = useState<number>();
  const { user } = useUserContext();
  const { flow, getPersonList, replaceValuesInTitle } = useFlowContext();
  const dispatchFlow = useFlowDispatchContext();

  useEffect(() => {
    if (!flow.section) {
      return;
    }
    setActiveSection(flow.section);
  }, [flow.section]);

  const handleEditClick = (section: number, set: number, index?: number) => {
    if (!user.accessToken) {
      return;
    }
    // console.log("index", index);
    // return;
    const setData = flowSets.find((s) => s.id === set && s.section === section);
    // console.log(setData);
    const action = {
      type: "set-edit",
      payload: {
        section,
        set,
        setIndex: index ? index : 0,
        prevPosition: {
          section: flow.section,
          set: flow.set,
          setIndex: flow.setIndex,
          status: flow.status,
        },
      },
    } as FlowActionSetEdit;
    if (setData?.multiple) {
      action.payload.status = "set-edit-finished";
    }
    if (flow.status === "summary") {
      // action.payload.status = "summary-edit";
    }

    dispatchFlow(action);
  };

  const handleSetActiveSectionClick = (id: number) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    setActiveSection(id);
  };

  const processItems = () => {
    if (flow.values.length === 0) {
      return [];
    }

    const flowItems = flowData
      .filter((f) => {
        if (flow.section !== f.section && flow.section >= 100) {
          return false;
        }
        return f.excludeFromSummary !== true && !f.hidden;
      })
      .reduce((acc, fd) => {
        const ifIdExist = acc.find((e) => e.id === fd.id);
        if (!ifIdExist) {
          return [...acc, fd];
        }
        return acc;
      }, [] as FlowItem[]);

    const ou = flowItems.reduce((prev, item) => {
      const out = { ...prev };
      const key = `${item.section}.${item.set}`;
      if (!out[key]) {
        out[key] = [];
      }

      if (item.multiple) {
        const name = item.multiple;
        const label = item.subsectionTitle;

        const v = flow.values.find((v) => v.name === name);
        if (v) {
          const multOut = Object.values(v.value).map((val) => {
            if (typeof val === "string") {
              return val;
            }
            return val
              .reduce((acc, vl) => {
                if (vl.name === "type") return acc;
                if (["person-relation", "relation"].includes(vl.name)) {
                  const relLabel = [
                    ...RELATION_OPTIONS,
                    ...FAMILY_RELATION_OPTIONS,
                  ].find((o) => o.value === vl.value.toString())?.label;
                  if (relLabel) {
                    return [...acc, relLabel.toLowerCase()];
                  }
                }
                return [...acc, vl.value];
              }, [])
              .join(" ");
          });
          out[key].push({ label: label, val: multOut });
        }
      } else {
        item.items.map((it) => {
          const label = replaceValuesInTitle(processQuestionLabel(it.label));
          const v = it.fields.reduce((prev, f) => {
            const acc = [...prev];
            if (f.type === "persons-numbers") {
              return acc;
            }

            const val = flow.values.find((i) => i.name === f.name);
            if (!val || val.value.toString().length === 0) return acc;

            if (
              f.type === "select" ||
              f.type === "checkbox" ||
              f.type === "radio" ||
              f.type === "assets-select"
            ) {
              const fv = val.value;
              return fv.map((vl) => {
                const res = f.options?.filter((o) => o.value === vl);
                return res.map((r) => replaceValuesInTitle(r.label));
              });
            }

            if (f.type === "persons-select") {
              return val.value.reduce((acc, vl) => {
                const person = getPersonList().find((i) => i.id === vl);
                if (person) {
                  return [...acc, [getPersonFullLabel(person)]];
                }
                return acc;
              }, []);
            }

            if (f.type === "persons-distribution") {
              return val.value.reduce((acc, vl) => {
                const [pId, pVal] =
                  Object.entries(vl).length > 0
                    ? Object.entries(vl)[0]
                    : [undefined, undefined];
                const person = getPersonList().find((i) => i.id === pId);
                if (!pId || !pVal) {
                  return acc;
                }
                if (person) {
                  return [...acc, [`${getPersonFullLabel(person)}, ${pVal}%`]];
                }
                return acc;
              }, []);
            }

            acc.push(val.value);
            return acc;
          }, []);
          if (v.length > 0) {
            out[key].push({ label: label, val: v });
          }
        });
      }
      return out;
    }, {} as SummaryType);

    const sets = flowSets.filter((s) => s.multiple);
    if (sets.length > 0) {
      sets.map((s) => {
        const setData = flowSets.find(
          (st) => st.id === s.id && st.section === s.section
        );
        const name = s.multiple;
        const valuesForSet = flow.values.find((v) => v.name === name);
        if (!valuesForSet || !valuesForSet.value) {
          return;
        }
        const vals = [];
        Object.values(valuesForSet.value).map((values) => {
          if (typeof values === "string") {
            return;
          }

          if (s.multiple === "savings") {
            const dt = [];

            const type = values
              .find((v) => v.name === "savings-description")
              ?.value.toString();
            if (type) {
              dt.push(type);
            }

            const price = values
              .find((v) => v.name === "savings-value")
              ?.value.toString();
            if (price) {
              dt.push(price + " kr");
            }

            vals.push(dt.join(", "));
          }
          if (s.multiple === "real-estate") {
            const dt = [];

            const desc = values
              .find((v) => v.name === "real-estate-address")
              ?.value.toString();
            if (desc) {
              dt.push(desc);
            }
            const price = values
              .find((v) => v.name === "real-estate-price")
              ?.value.toString();
            if (price) {
              dt.push(price + " kr");
            }

            vals.push(dt.join(", "));
          }
          if (s.multiple === "personal-estate") {
            const dt = [];

            const typeValue = values
              .find((v) => v.name === "personal-estate-type")
              ?.value.toString();
            const type = ASSET_PERSONAL_ESTATE_TYPES.find((item) => item.value === typeValue)?.label || "";
            if (type) {
              dt.push(type);
            }

            const make = values
              .find((v) => v.name === "personal-estate-make")
              ?.value.toString();
            if (make) {
              dt.push(make);
            }

            const year = values
              .find((v) => v.name === "personal-estate-year")
              ?.value.toString();
            if (year) {
              dt.push(year);
            }

            const price = values
              .find((v) => v.name === "personal-estate-value")
              ?.value.toString();
            if (price) {
              dt.push(price + " kr");
            }

            vals.push(dt.join(", "));
          }
          if (s.multiple === "securities") {
            const dt = [];

            const typeValue = values
              .find((v) => v.name === "securities-type")
              ?.value.toString();
            const type = ASSET_SECURITIES_TYPES.find((item) => item.value === typeValue)?.label || "";
            if (type) {
              dt.push(type);
            }

            const desc = values
              .find((v) => v.name === "asset-bequeath-description")
              ?.value.toString();
            if (desc) {
              dt.push(desc);
            }

            const price = values
              .find((v) => v.name === "securities-value")
              ?.value.toString();
            if (price) {
              dt.push(price + " kr");
            }

            vals.push(dt.join(", "));
          }
        });
        ou[`${s.section}.${s.id}.1`] = [{ label: setData.title, val: vals }];
      });
    }

    const toOut = {} as {
      [k: string]: {
        set: string;
        index?: string;
        items: { label: string; val: string[] }[];
      }[];
    };
    for (const [key, items] of Object.entries(ou)) {
      const [section, set, index] = key.split(".");
      if (flow.section != parseInt(section) && flow.section >= 100) {
        continue;
      }
      if (!items.length) {
        continue;
      }
      if (!toOut[section]) {
        toOut[section] = [];
      }
      toOut[section].push({ set, items, index });
      toOut[section] = toOut[section].sort((a, b) => {
        return a.set - b.set;
      });
    }

    return toOut;
  };

  const renderSections = () => {
    const data = processItems();

    if (!data[flow.section]) {
      data[flow.section] = [{ set: "1", items: [] }];
    }

    let sectionIndex = 0;
    const sectionLength = data ? Object.entries(data).length : 0;
    const render = [];
    for (const [section, sets] of Object.entries(data)) {
      const sectionId = parseInt(section);

      if (
        ["edit", "edit-finished", "set-edit-finished"].includes(flow.status) &&
        sectionId !== flow.section
      ) {
        continue;
      }

      const [sectionTitle, sectionType] = flowSections.reduce((acc, s) => {
        if (s.id === sectionId) {
          return [s.title, s.type];
        }
        return acc;
      }, [] as string[]);
      if (sectionType === "none") {
        continue;
      }

      sectionIndex++;
      const isSectionActive = activeSection === sectionId;
      let isSectionEditable =
        flow.status === "summary" ||
        (isSectionActive &&
          (flow.section !== sectionId ||
            (flow.section === sectionId && flow.status === "finished")));

      const sectionHeader = !isSectionEditable ? "Livsplanering" : "Summering";
      const toRender = [] as ReactNode[];
      sets.map((st) => {
        const { set, items, index } = st;
        const setId = parseInt(set);

        if (
          (flow.prevPosition &&
            flow.prevPosition.section === sectionId &&
            flow.prevPosition.set === setId) ||
          ["edit", "edit-finished", "set-edit-finished"].includes(flow.status)
        ) {
          isSectionEditable = false;
        }

        const o = (
          <div
            key={`${set}-${items.length}`}
            className="relative border-b-[1px] border-[#CECABA] pb-5 last:border-none last:pb-0"
          >
            <div className="flex flex-col gap-5">
              {isSectionEditable && (
                <div className="flex items-start gap-2 font-intermedium text-[#736F62]">
                  <div className="text-[13px] italic">
                    Läs igenom dina svar så att de stämmer innan du fortsätter
                  </div>
                  <div
                    className="flex shrink-0 cursor-pointer flex-row gap-1 pt-1 text-[11px] leading-none"
                    onClick={() =>
                      handleEditClick(sectionId, setId, parseInt(index))
                    }
                  >
                    <div
                      className={`border-b-[1px] border-theme-primary2 pb-[2px] chapter_${flow.section}_edit_${section}`}
                    >
                      Ändra
                    </div>
                    {getPencilIcon("#736F62")}
                  </div>
                </div>
              )}
              {items.map((item, index) => {
                return (
                  <div
                    key={`${item.label}-${index}`}
                    className={`flex flex-col gap-1 text-[13px] ${
                      flow.status === "finished" && "pl-4"
                    }`}
                  >
                    <div className="flex grow flex-row items-center justify-between gap-2">
                      {item.label && (
                        <div className="font-intermedium">{item.label}</div>
                      )}
                    </div>
                    {typeof item.val === "string" && (
                      <div className="font-interlight italic">{item.val}</div>
                    )}
                    {typeof item.val === "object" &&
                      item.val.map((str, ind) => (
                        <div
                          key={`${str}-${ind}`}
                          className="font-interlight italic"
                        >
                          {str}
                        </div>
                      ))}
                  </div>
                );
              })}
            </div>
          </div>
        );
        toRender.push(o);
      });
      const s = (
        <div
          key={`section-${section}`}
          className={`flex flex-col gap-5 border-[#CECABA] ${
            isSectionActive && sectionIndex === 1 && sectionLength > 1
              ? `mb-3 border-b-[1px] pb-8`
              : ""
          } ${
            isSectionActive &&
            sectionIndex === sectionLength &&
            sectionLength > 1
              ? `mt-3 border-t-[1px] pt-8`
              : ""
          } ${
            isSectionActive && sectionIndex > 1 && sectionIndex < sectionLength
              ? `my-3 border-t-[1px] border-b-[1px] py-8`
              : ""
          }`}
        >
          {activeSection === parseInt(section) ? (
            <div>
              <RightColumnHeader
                title={sectionHeader}
                subtitle={sectionTitle}
              />
            </div>
          ) : (
            <div
              className={`flex cursor-pointer  text-[12px] uppercase tracking-[2.4px] text-theme-neutral3 ${
                fullPage ? "" : "justify-end"
              }`}
              onClick={() => handleSetActiveSectionClick(parseInt(section))}
            >
              <div className="flex items-center gap-2">
                {fullPage ? (
                  <>
                    {getExpandCircleIcon()}
                    <span>{sectionTitle}</span>
                  </>
                ) : (
                  <>
                    <span>{sectionTitle}</span>
                    {getExpandCircleIcon()}
                  </>
                )}
              </div>
            </div>
          )}
          {isSectionActive && toRender}
          {/*{toRender}*/}
        </div>
      );
      render.push(s);
    }

    return render;
  };

  return <div className="flex flex-col gap-2">{renderSections()}</div>;
}
